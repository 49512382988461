import React from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";



import btn from './btn_google_signin_dark_normal_web.png';
const GoogleSignIn = () => {
return (
<button onClick={() => Auth.federatedSignIn({
  provider: CognitoHostedUIIdentityProvider.Google
})}>
<img src={btn} alt="Google Sign In button"
     className="googleSignIn"
     style={{height:"45px", width:"190px"}}/>
</button>
);
}
export default GoogleSignIn;