/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b2d0673a-8050-4a8e-9dee-cae4ded77e0b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_CbYPJCppP",
    "aws_user_pools_web_client_id": "550rl3ha23nfon7lnkq0tpsula",
    "oauth": {
        "domain": "googlesignin-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://main.d25l88edsdobl9.amplifyapp.com/,http://localhost:3000/,http://localhost:3000/,http://localhost:3000/",
        "redirectSignOut": "https://main.d25l88edsdobl9.amplifyapp.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "enrollFintech",
            "endpoint": "https://wr8xs3b63i.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "testingRest",
            "endpoint": "https://d3g78aiffj.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "partionedTest2",
            "endpoint": "https://as9yk56zhf.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "itemstest-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "partionedTest2-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_mobile_analytics_app_id": "b3cd0b4ecdee4120af66068e9e30a768",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
