// src/App.js

// import useEffect hook
import React, { useEffect } from 'react';
import './App.css';
import GoogleSignIn from './GoogleSignIn';
//import { Flex } from 'skylight-react';

// import Hub
import { Auth, Hub, API } from 'aws-amplify'


// To federated sign in from Google
/*const App = () => {

    useEffect(() => {
        const ga = window.gapi && window.gapi.auth2 ? 
            window.gapi.auth2.getAuthInstance() : 
            null;

        if (!ga) createScript();
    }, [])

    const signIn = () => {
        const ga = window.gapi.auth2.getAuthInstance();
        ga.signIn().then(
            googleUser => {
                getAWSCredentials(googleUser);
                console.log ("sign in")
            },
            error => {
                console.log(error);
            }
        );
    }

    const getAWSCredentials = async (googleUser) => {
        const { id_token, expires_at } = googleUser.getAuthResponse();
        const profile = googleUser.getBasicProfile();
        let user = {
            email: profile.getEmail(),
            name: profile.getName()
        };
        
        const credentials = await Auth.federatedSignIn(
            'google',
            { token: id_token, expires_at },
            user
        );
        console.log('credentials', credentials);
    }

    const createScript = () => {
        // load the Google SDK
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.async = true;
        script.onload = initGapi;
        document.body.appendChild(script);
    }

    const initGapi = () => {
        // init the Google SDK client
        const g = window.gapi;
        g.load('auth2', function() {
            g.auth2.init({
                client_id: '327178610718-ikkrl8tv7hqad82c6682r8o4opai8k4o.apps.googleusercontent.com',
                plugin_name:'Matrix OBH Google Login',
                // authorized scopes
                scope: 'email'
            });
        });
    }
    const signOut = () =>{
      Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
    }

    return (
        <div>
            <button onClick={signIn}>Sign in with Google</button>
            <button onClick={signOut}>Sign Out</button>
        </div>
    );
}
*/

let currentUser = checkUser()
function checkUser() {
  Auth.currentAuthenticatedUser()
    .then(user => {
      console.log(user)
      console.log("Hello22:}" + user.attributes.email)
      currentUser = user.attributes.email
    })
    .catch(err => console.log(err));
}

function signOut() {
  Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
}

function App(props) {
  // in useEffect, we create the listener
  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data
      console.log('A new auth event has happened: ', data)
       if (payload.event === 'signIn') {
         console.log('a user has signed in!')
       }
       if (payload.event === 'signOut') {
         console.log('a user has signed out!')
       }
    });
    API.get("testingRest" ,"/items/1212").then((res) => console.log(res))
    API.get("partionedTest2" ,"/partionedTest2/1313").then((res) => console.log(res))
  }, [])
  return (
    <div className="App">
      <header className="App-header">
        <p>user: 
       {currentUser}   
        </p>
        <button onClick={() => Auth.federatedSignIn()}>Sign In</button>
        <button onClick={checkUser}>Check User</button>
        <button onClick={signOut}>Sign Out</button>
        <button onClick={() => Auth.federatedSignIn({provider: 'Facebook'})}>Sign In with Facebook</button>
        <button onClick={() => Auth.federatedSignIn({provider: 'Google'})}>Sign In with Googlee</button>

        <GoogleSignIn/>
      </header>
    </div>
  );
}

export default App